import { cn } from "@/utils/cn"
import { FieldError } from "react-hook-form"
import { Icon, IconType } from "./icon"
import {
  CheckCircledIcon,
  ExclamationTriangleIcon,
} from "@radix-ui/react-icons"
import { forwardRef } from "react"

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string
  label?: string
  supportText?: string
  suffix?: string
  icon?: IconType
  iconOnClick?: () => void
  iconClassName?: string
  hasError?: FieldError
  hasSuccess?: boolean
  className?: string
  fullWidth?: boolean
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      label,
      supportText,
      suffix,
      icon,
      iconOnClick,
      iconClassName,
      hasError,
      hasSuccess,
      fullWidth,
      ...props
    }: InputProps,
    ref
  ): JSX.Element => {
    return (
      <div className={cn("flex flex-col", fullWidth && "w-full")}>
        {label && (
          <label
            htmlFor={id}
            className="pb-1 text-sm uppercase tracking-wide text-typography-grey-1"
          >
            {label}
          </label>
        )}
        <div className="relative flex items-center justify-start">
          {icon && (
            <span
              onClick={iconOnClick}
              onKeyDown={iconOnClick}
              role="button"
              tabIndex={0}
            >
              <Icon
                name={icon}
                className={cn(
                  "absolute left-3 top-[13px] text-typography-grey-1",
                  iconClassName
                )}
              />
            </span>
          )}
          <input
            ref={ref}
            name={id}
            className={cn(
              `
                h-10 w-full rounded-md border border-day-grey-2 bg-transparent px-3 py-2 text-sr-text-primary
                dark:border-night-grey-2 dark:placeholder:text-white/50
                focus:border-black focus:outline-none focus:ring-0 focus:dark:border-white
                placeholder:text-base placeholder:text-sr-text-tertiary
              `,
              { "pl-9": icon },
              { "opacity-50": props.disabled },
              { "border-error": hasError },
              { "border-success": hasSuccess }
            )}
            {...props}
          />
          {suffix && (
            <span className="absolute right-3 text-typography-grey-1">
              {suffix}
            </span>
          )}
          {(hasError || hasSuccess) && (
            <div
              className={`
                pointer-events-none absolute bottom-4 right-2 top-2 h-6 w-40 bg-gradient-to-l from-white to-transparent
                dark:from-black
                dim:from-sr-800
              `}
            ></div>
          )}
          {hasError && (
            <ExclamationTriangleIcon className="absolute right-3 text-error" />
          )}
          {hasSuccess && (
            <CheckCircledIcon className="absolute right-3 text-success" />
          )}
        </div>

        {hasError?.message ? (
          <p className="mt-1.5 text-sm text-error">{hasError?.message}</p>
        ) : (
          supportText && (
            <p className="mt-2 block text-sm text-typography-grey-2">
              {supportText}
            </p>
          )
        )}
      </div>
    )
  }
)
