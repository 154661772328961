import React from "react"
import { cn } from "@/utils/cn"

const HR = ({ className = "" }): JSX.Element => {
  return (
    <hr
      className={cn(
        "w-full border-0 border-b border-b-sr-border-tertiary",
        className
      )}
    />
  )
}

export default HR
