import React, { createContext, useContext, useEffect, useState } from "react"
import { motion } from "framer-motion"
import clsx from "clsx"
import { cn } from "@/utils/cn"

const TabContext = createContext<{
  activeTab: string
  onTabChange: (value: string) => void
}>({
  activeTab: "",
  onTabChange: () => {},
})

interface TabProviderProps {
  children: React.ReactNode
  defaultTab: string
  onChange?: (value: string) => void
  keepState?: boolean
  className?: string
  activeTab?: string
}

const TabProvider: React.FC<TabProviderProps> = ({
  children,
  defaultTab,
  onChange,
  keepState = false,
  className,
  activeTab: initialActiveTab,
}) => {
  const [activeTab, setActiveTab] = useState(defaultTab || "")

  useEffect(() => {
    if (keepState) {
      const tabFromUrl = window.location.search.split("tab=")[1]
      if (tabFromUrl) {
        setActiveTab(tabFromUrl)
        onChange && onChange(tabFromUrl)
      }
    }
  }, [])

  useEffect(() => {
    if (initialActiveTab) {
      setActiveTab(initialActiveTab)
    }
  }, [initialActiveTab])

  const handleTabChange = (value: string): void => {
    setActiveTab(value)
    onChange && onChange(value)
    if (keepState) {
      const newUrl = window.location.pathname + `?tab=${value}`
      window.history.replaceState(null, "", newUrl)
    }
  }

  return (
    <TabContext.Provider value={{ activeTab, onTabChange: handleTabChange }}>
      <div className={clsx("flex w-full flex-col", className)}>{children}</div>
    </TabContext.Provider>
  )
}

interface TabProps {
  value: string
  children: React.ReactNode
  disabled?: boolean
}

const Tab: React.FC<TabProps> = ({ value, children, disabled }) => {
  const { activeTab, onTabChange } = useContext(TabContext)

  return (
    <button
      type="button"
      className={cn("whitespace-nowrap", {
        "text-black dark:text-white": value === activeTab,
        "text-typography-grey-1": value !== activeTab,
      })}
      onClick={() => onTabChange(value)}
      disabled={disabled}
    >
      {children}
      {value === activeTab && (
        <motion.div
          className={`
            relative -bottom-[8px] z-10 border-b border-black
            dark:border-white
          `}
          layoutId="underline"
        />
      )}
    </button>
  )
}

interface TabListProps {
  children?: React.ReactNode
  contained?: boolean
  className?: string
}

const TabList: React.FC<TabListProps> = ({
  children,
  contained,
  className,
}) => {
  return (
    <nav
      className={clsx(
        "relative border-b border-sr-border-quaternary",
        className
      )}
    >
      <div
        className={clsx(
          `
            flex w-full gap-x-8 overflow-x-auto py-2 pr-12
            sm:pr-0
          `,
          contained && "container"
        )}
      >
        <div
          className={`
            pointer-events-none absolute bottom-1 right-0 h-8 w-24 bg-gradient-to-l from-sr-bg-primary to-transparent
          `}
        ></div>
        {children}
      </div>
    </nav>
  )
}

interface TabContentProps {
  value: string
  className?: string
  children: React.ReactNode
}

const TabContent: React.FC<TabContentProps> = ({
  value,
  className,
  children,
}) => {
  const { activeTab } = useContext(TabContext)

  return value === activeTab ? (
    <div className={clsx("pt-4", className)}>{children}</div>
  ) : null
}

export const Tabs = {
  Provider: TabProvider,
  Tab,
  List: TabList,
  Content: TabContent,
}
