import { create } from "zustand"
import { persist, createJSONStorage } from "zustand/middleware"

type ThemeMode = "light" | "dim" | "dark"

interface ThemeStore {
  mode: ThemeMode
  toggleMode: () => void
  setMode: (mode: ThemeMode) => void
  dark: boolean
  setDark: (dark: boolean) => void
  toggle: () => void
  navbar: "full" | "reduced" | "hidden"
  footer: "full" | "hidden"
  setNavbar: (navbar: "full" | "reduced" | "hidden") => void
  setFooter: (footer: "full" | "hidden") => void
  userToggled: boolean
  setUserToggled: (userToggled: boolean) => void
  noticeBarDismissed: boolean
  setNoticeBarDismissed: (noticeBarDismissed: boolean) => void
}

export const useThemeStore = create<ThemeStore>()(
  persist(
    (set) => ({
      mode: "light",
      toggleMode: () =>
        set((state) => ({
          mode:
            state.mode === "light"
              ? "dim"
              : state.mode === "dim"
                ? "dark"
                : "light",
        })),
      dark: false,
      setMode: (mode) => set({ mode }),
      setDark: (dark) => set({ dark }),
      toggle: () => set((state) => ({ dark: !state.dark })),
      navbar: "full",
      footer: "full",
      setNavbar: (navbar) => set({ navbar }),
      setFooter: (footer) => set({ footer }),
      userToggled: false,
      setUserToggled: (userToggled) => set({ userToggled }),
      noticeBarDismissed: false,
      setNoticeBarDismissed: (noticeBarDismissed) =>
        set({ noticeBarDismissed }),
    }),
    {
      name: "theme-mode",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)
