import React, { ReactNode, isValidElement, Children } from "react"
import { Dialog } from "./dialog"
import { Popover } from "./popover"
import { isMobile } from "react-device-detect"
import * as PopoverPrimitive from "@radix-ui/react-popover"

type PopoverContentProps = PopoverPrimitive.PopoverContentProps & {
  children?: ReactNode
}

type PopoverComponentProps = {
  children: ReactNode
  mobile?: Mobile
  title?: string
  open?: boolean
  onOpenChange?: (open: boolean) => void
} & PopoverContentProps

type Mobile = "normal" | "full"

const PopoverTrigger = ({
  children,
  ...props
}: {
  children: ReactNode
}): JSX.Element => {
  return <div {...props}>{children}</div>
}

const PopoverContent = ({ children }: { children: ReactNode }): JSX.Element => {
  return <>{children}</>
}

const PopoverComponent = ({
  children,
  mobile,
  title,
  open,
  onOpenChange,
  ...props
}: PopoverComponentProps): JSX.Element => {
  let trigger: ReactNode = null
  let content: ReactNode = null

  Children.forEach(children, (child) => {
    if (isValidElement(child)) {
      if (child.type === PopoverTrigger) {
        trigger = child
      } else if (child.type === PopoverContent) {
        content = child
      }
    }
  })

  if (isMobile && mobile === "full") {
    return (
      <Dialog>
        <Dialog.Trigger>{trigger}</Dialog.Trigger>
        <Dialog.Content title={title}>
          <div className="p-4">{content}</div>
        </Dialog.Content>
      </Dialog>
    )
  }

  return (
    <Popover open={open} onOpenChange={onOpenChange}>
      <Popover.Trigger className="w-full">{trigger}</Popover.Trigger>
      <Popover.Content {...props}>{content}</Popover.Content>
    </Popover>
  )
}

PopoverComponent.Trigger = PopoverTrigger
PopoverComponent.Content = PopoverContent

export { PopoverComponent as Popover }
