import clsx from "clsx"
import { useEffect, useRef } from "react"

interface AvatarProps {
  src?: string | null
  alt?: string
  className?: string
}

export const Avatar = ({ src, alt, className }: AvatarProps): JSX.Element => {
  const mediaRef = useRef(null)
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && src) {
            if (entry.target instanceof HTMLImageElement) {
              entry.target.src = src
              return
            }
          }
          if (entry.target instanceof HTMLImageElement) {
            entry.target.src = ""
            return
          }
        })
      },
      {
        threshold: 0.1,
      }
    )

    if (mediaRef.current) {
      observer.observe(mediaRef.current)
    }

    return () => {
      if (mediaRef.current) {
        observer.unobserve(mediaRef.current)
      }
    }
  }, [src])
  return (
    <div
      className={clsx(
        `rounded-ful relative aspect-square shrink-0 overflow-hidden rounded-full`,
        !src && "dark:invert",
        className
      )}
    >
      <img
        ref={mediaRef}
        src={src || "/assets/avatar.svg"}
        className="object-cover"
        alt={alt || "Avatar"}
      />
    </div>
  )
}
