import React, { ForwardedRef, ReactNode, forwardRef, useEffect } from "react"
import * as DialogPrimitive from "@radix-ui/react-dialog"
import { Cross1Icon } from "@radix-ui/react-icons"
import { cn } from "@/utils/cn"

interface DialogContentProps {
  title?: string
  disableClose?: boolean
  className?: string
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl"
  children: ReactNode
  onOpenChange?: (open: boolean) => void
  open?: boolean
  ref?: ForwardedRef<HTMLDivElement>
  pointerEventsAuto?: boolean
  header?: ReactNode
  [x: string]: any
}

const sizeSpec = {
  xs: `lg:min-w-[200px] lg:max-w-[520px]`,
  sm: `lg:min-w-[300px] lg:max-w-[600px]`,
  md: `lg:min-w-[420px] lg:max-w-[800px]`,
  lg: `lg:min-w-[598px]`,
  xl: `lg:min-w-[700px]`,
  "2xl": `lg:min-w-[900px]`,
}

export const DialogContent = forwardRef<HTMLDivElement, DialogContentProps>(
  (
    {
      title,
      disableClose,
      className,
      size = "md",
      children,
      pointerEventsAuto,
      header,
      ...props
    }: DialogContentProps,
    ref
  ) => {
    const sizeClassName = sizeSpec[size]
    useEffect(() => {
      if (pointerEventsAuto) {
        const observer = new MutationObserver(() => {
          if (document.body.style.pointerEvents !== "auto") {
            document.body.style.pointerEvents = "auto"
          }
        })

        observer.observe(document.body, {
          attributes: true,
          attributeFilter: ["style"],
        })

        return () => {
          observer.disconnect()
        }
      }
    }, [pointerEventsAuto])
    return (
      <DialogPrimitive.Portal>
        <DialogPrimitive.Overlay className="DialogOverlay" />
        <DialogPrimitive.Content className="DialogContent" {...props} ref={ref}>
          <aside
            className={cn(
              `
                h-screen min-w-[100vw] border-sr-border-primary bg-sr-bg-primary
                lg:h-auto lg:rounded-md lg:border
              `,
              sizeClassName
            )}
          >
            <header
              className={`
                flex h-[65px] items-center justify-between border-b border-sr-border-primary px-4
                lg:h-auto lg:p-4
              `}
            >
              {header ? (
                header
              ) : (
                <>
                  <h2 className="text-sm font-semibold uppercase">{title}</h2>
                  {!disableClose && (
                    <DialogPrimitive.Close
                      className={`
                        flex h-9 w-9 items-center justify-center rounded-md bg-sr-bg-tertiary duration-300
                        dark:hover:bg-sr-700
                        hover:bg-sr-400
                      `}
                      aria-label="Close"
                    >
                      <Cross1Icon />
                    </DialogPrimitive.Close>
                  )}
                </>
              )}
            </header>
            <section
              id="scrollableDialogDiv"
              className={cn(
                `
                  max-h-[90dvh] overflow-y-auto p-4
                  lg:max-h-[70vh]
                `,
                className
              )}
            >
              {children}
            </section>
          </aside>
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    )
  }
)

const Dialog = Object.assign(DialogPrimitive.Root, {
  Trigger: DialogPrimitive.Trigger,
  Content: DialogContent as React.FC<DialogContentProps>,
})

export { Dialog }
