import { HEAP_ACCOUNT_ID } from "@/constants/environment"

export const loadHeap = (): void => {
  const existingScript = document.getElementById("heap")
  if (!existingScript) {
    const script = document.createElement("script")
    script.text = `
        ;(window.heap = window.heap || []),
        (heap.load = function (e, t) {
          ;(window.heap.appid = e), (window.heap.config = t = t || {})
          var r = document.createElement('script')
          ;(r.type = 'text/javascript'),
            (r.async = !0),
            (r.src = 'https://cdn.heapanalytics.com/js/heap-' + e + '.js')
          var a = document.getElementsByTagName('script')[0]
          a.parentNode.insertBefore(r, a)
          for (
            var n = function (e) {
                return function () {
                  heap.push(
                    [e].concat(Array.prototype.slice.call(arguments, 0))
                  )
                }
              },
              p = [
                'addEventProperties',
                'addUserProperties',
                'clearEventProperties',
                'identify',
                'resetIdentity',
                'removeEventProperty',
                'setEventProperties',
                'track',
                'unsetEventProperty'
              ],
              o = 0;
            o < p.length;
            o++
          )
            heap[p[o]] = n(p[o])
        })
      heap.load('${HEAP_ACCOUNT_ID}')
    `
    script.id = "heap"
    document.body.appendChild(script)
  }
}

export interface Heap {
  appid: string
  identity: string
  load: (accountId: string) => void
  identify: (user: string) => void
  addUserProperties: (properties: Record<string, string>) => void
  addEventProperties: (properties: Record<string, string>) => void
  track: (
    event: string,
    properties?: Record<string, string | number | undefined>
  ) => void
  push?: (args: unknown[]) => void
}

declare global {
  interface Window {
    heap: Heap
  }
}

export const heapTrack = (
  event: string,
  properties?: Record<string, string | number | undefined>
): void => {
  if (
    !window.heap?.appid &&
    window.heap.appid.toLocaleLowerCase().includes("invalid")
  ) {
    return
  }
  try {
    window.heap?.track(event, properties)
  } catch (err) {
    return
  }
}

export const heapIdentify = (userId: string): void => {
  const maxRetries = 3
  let retry = 0

  const retryIdentify = (): void => {
    if (maxRetries === retry) return
    retry = retry + 1
    setTimeout(function () {
      if (window.heap instanceof Node) {
        retryIdentify()
        return
      }
      window.heap?.identify(userId)
    }, 3000)
  }
  setTimeout(function () {
    if (window.heap instanceof Node) {
      retryIdentify()
      return
    }
    window.heap?.identify(userId)
  }, 1000)
}
