import { User } from "@/graphql/generated/apollo/graphql"
import { useFollowers } from "@/hooks/useFollowers"
import { trpc } from "@/trpc/client"
import { Avatar } from "@/ui/avatar"
import { Button } from "@/ui/button"
import { EthformatAddress } from "@/ui/ethaddress"
import { Loader } from "@/ui/loader"
import { cn } from "@/utils/cn"
import * as Popover from "@radix-ui/react-popover"
import clsx from "clsx"
import makeBlockie from "ethereum-blockies-base64"
import Image from "next/image"
import Link from "next/link"
import { useState } from "react"

interface UserProfileDialogProps {
  children: React.ReactNode
  ethAddress: string | undefined
  side?: "left" | "right" | "bottom" | "top"
  hideAvatar?: boolean
  sideOffset?: number
  isArtist?: boolean
}

const UserProfileDialog = ({
  children,
  ethAddress,
  side = "bottom",
  hideAvatar,
  sideOffset = 8,
}: UserProfileDialogProps): JSX.Element => {
  const [open, setOpen] = useState(false)
  const address = ethAddress ?? ""
  const { data, isFetching } = trpc.profile.getByAddresses.useQuery(
    {
      addresses: [address.toLowerCase()],
      creations: true,
    },
    {
      enabled: open,
      staleTime: 1000 * 60 * 5, // 5 minutes
    }
  )
  const user = data?.users?.[0] as User | undefined
  const displayArtowrks = user?.total_creations_count
    ? data?.creations
    : data?.collections

  const handleMouseEnter = (): void => {
    setOpen(true)
  }
  const handleMouseLeave = (): void => {
    setOpen(false)
  }

  const { isFollowing, isMutating, handleClickFollow } = useFollowers(
    user as User
  )
  if (!ethAddress) return <>{children}</>
  return (
    <Popover.Root open={open}>
      <Popover.Trigger
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={clsx("flex items-center justify-center", {
          "!inline": hideAvatar,
        })}
      >
        {children}
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          className={cn(
            `
              add-after-blank z-[50000] h-[60px] min-w-[312px] overflow-hidden rounded-md border
              border-sr-border-primary bg-sr-bg-primary p-3 duration-300
            `,
            {
              "h-[196px]":
                !isFetching &&
                user &&
                (user?.total_creations_count > 0 ||
                  user?.total_collections_count > 0),
              "h-[90px]":
                !isFetching &&
                user?.total_creations_count === 0 &&
                user?.total_collections_count === 0,
            }
          )}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          sideOffset={sideOffset}
          side={side}
        >
          {isFetching ? (
            <div className="flex flex-col items-center justify-center">
              <Loader kind="simple" />
            </div>
          ) : (
            <div className="flex flex-col">
              <div className="flex items-center justify-between">
                <div className="flex w-full justify-between">
                  <div className="flex items-center gap-x-0">
                    <Link href={`/${user?.username}`}>
                      <Avatar
                        src={
                          user?.avatar ||
                          makeBlockie(user?.ethereum_address || "0x")
                        }
                        alt={`${user?.username}'s Avatar`}
                        className="h-8 w-8"
                      />
                    </Link>
                    <Link
                      className={user?.username ? "" : "pointer-events-none"}
                      href={`/${user?.username}`}
                    >
                      <span className="ml-2">
                        @{user?.username || EthformatAddress(ethAddress)}
                      </span>
                    </Link>
                  </div>
                  <Button
                    className={clsx(
                      "text-sm uppercase",
                      isMutating && "animate-pulse"
                    )}
                    onClick={() => handleClickFollow(!isFollowing)}
                  >
                    {isFollowing ? "Following" : "Follow"}
                  </Button>
                </div>
                <nav>
                  {/* TODO: Implement Follow functionality */}
                  {/* <FollowButton /> */}
                </nav>
              </div>
              <ul className="my-4 flex gap-x-3 text-sm text-typography-grey-1">
                {user && user?.total_creations_count > 0 ? (
                  <li>
                    <span
                      className={`
                        text-black
                        dark:text-white
                      `}
                    >
                      {user?.total_creations_count}
                    </span>{" "}
                    Creations
                  </li>
                ) : (
                  <li>
                    <span
                      className={`
                        text-black
                        dark:text-white
                      `}
                    >
                      {user?.total_collections_count || 0}
                    </span>{" "}
                    Collected
                  </li>
                )}
                <li>
                  <span
                    className={`
                      text-black
                      dark:text-white
                    `}
                  >
                    {user?.total_followers_count || 0}
                  </span>{" "}
                  Followers
                </li>
              </ul>
              <ul className="flex justify-start gap-x-4">
                {displayArtowrks &&
                  displayArtowrks?.map((artwork) => {
                    if (!artwork) return <></>
                    const image =
                      artwork.nft_image?.image_medium || artwork.image
                    const video = artwork.video
                    return (
                      <li
                        key={artwork.universal_token_id}
                        className="relative h-[88px] w-[88px] overflow-hidden rounded-md bg-day-grey-4"
                      >
                        <Link
                          href={`http://superrare.com/${artwork.contract_address}/${artwork.token_id}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {image && (
                            <Image
                              src={image}
                              blurDataURL={
                                artwork.nft_image?.image_blurred || image
                              }
                              fill
                              alt=""
                            />
                          )}
                          {video && (
                            <video
                              playsInline
                              className="max-h-full max-w-full"
                              src={video}
                              muted
                              autoPlay
                              loop
                            />
                          )}
                        </Link>
                      </li>
                    )
                  })}
              </ul>
            </div>
          )}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}

export default UserProfileDialog
