import parse from "html-react-parser"
import DOMPurify from "dompurify"
import React from "react"

function replaceNode(node: any) {
  return node
}

export default function html(html: string, opts: object = {}): React.ReactNode {
  return parse(DOMPurify.sanitize(html), {
    ...{
      replace: replaceNode,
    },
    ...opts,
  })
}
