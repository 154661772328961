import React, { ReactNode } from "react"
import * as CheckboxPrimitive from "@radix-ui/react-checkbox"
import { CheckIcon } from "@radix-ui/react-icons"
import { cn } from "@/utils/cn"

type CheckboxProps = CheckboxPrimitive.CheckboxProps & {
  children?: ReactNode
}

export const Checkbox = ({
  id,
  children,
  ...props
}: CheckboxProps): JSX.Element => (
  <div className="flex items-center">
    <CheckboxPrimitive.Root
      className={cn(
        "h-4 w-4 rounded-sm border border-sr-border-primary",
        props.disabled ? "border-sr-border-tertiary bg-sr-border-tertiary" : ""
      )}
      id={id}
      {...props}
    >
      <CheckboxPrimitive.Indicator className={cn("text-sr-text-primary")}>
        {!props.disabled && <CheckIcon />}
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
    <label
      className={cn(
        `
          flex-1 cursor-pointer pl-3 text-sr-text-primary
          sm:pl-2
        `,
        props.disabled ? "text-sr-text-tertiary" : ""
      )}
      htmlFor={id}
    >
      {children}
    </label>
  </div>
)
