// src/ui/time.tsx
import { cn } from "@/utils/cn"
import React, { useState, useEffect } from "react"

type TimeFormat = "24h" | "12h"

interface TimeSelectorProps {
  interval?: number
  format?: TimeFormat
  selected?: string
  onSelect?: (time: string) => void
  fromCurrentTime?: boolean
}

export const TimeSelector: React.FC<TimeSelectorProps> = ({
  interval = 30,
  format = "24h",
  selected,
  onSelect,
  fromCurrentTime,
}) => {
  const [times, setTimes] = useState<string[]>([])

  useEffect(() => {
    const times = []
    const now = new Date()
    const minutesSinceMidnight = now.getHours() * 60 + now.getMinutes()

    for (let i = 0; i < 24 * 60; i += interval) {
      if (fromCurrentTime && i < minutesSinceMidnight) continue

      const hh = Math.floor(i / 60)
      const mm = i % 60
      if (format === "24h") {
        times.push(
          `${hh.toString().padStart(2, "0")}:${mm.toString().padStart(2, "0")}`
        )
      } else {
        const period = hh < 12 ? "AM" : "PM"
        const hh12 = hh % 12 || 12
        times.push(
          `${hh12.toString().padStart(2, "0")}:${mm
            .toString()
            .padStart(2, "0")} ${period}`
        )
      }
    }
    setTimes(times)
  }, [interval, format, fromCurrentTime])

  const formatSelectedTime = (selectedTime: string): string => {
    const [hh24, mm] = selectedTime.split(":")
    const hh = parseInt(hh24)
    const period = hh < 12 ? "AM" : "PM"
    const hh12 = hh % 12 || 12
    return `${hh12.toString().padStart(2, "0")}:${mm} ${period}`
  }

  return (
    <div className="flex h-64 flex-col overflow-y-auto">
      {times.map((time, index) => (
        <button
          key={index}
          className={cn(
            `
              block w-full cursor-pointer rounded-md px-6 py-1
              hover:bg-sr-bg-secondary
            `,
            {
              "bg-black text-white hover:bg-black":
                time === (selected ? formatSelectedTime(selected) : ""),
            }
          )}
          onClick={() => {
            const [hh12, mmPeriod] = time.split(":")
            const [mm, period] = mmPeriod.split(" ")
            let hh = parseInt(hh12)
            if (period === "PM" && hh !== 12) hh += 12
            if (period === "AM" && hh === 12) hh = 0
            onSelect && onSelect(`${hh.toString().padStart(2, "0")}:${mm}`)
          }}
        >
          {time}
        </button>
      ))}
    </div>
  )
}
