import React, { ReactNode } from "react"
import * as TooltipPrimitive from "@radix-ui/react-tooltip"
import { cn } from "@/utils/cn"
import html from "@/utils/html"

type PopoverContentProps = TooltipPrimitive.TooltipContentProps & {
  children?: ReactNode
  centerContent?: boolean
  invert?: boolean
}

export function Tooltip({
  children,
  content,
  invert,
  centerContent = false,
  ...props
}: PopoverContentProps): JSX.Element {
  return (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root delayDuration={0}>
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
        <TooltipPrimitive.Content
          className={cn(
            "z-50 rounded-md border border-sr-border-primary bg-sr-bg-primary p-2 text-[11px] text-sr-text-primary",
            {
              "bg-sr-bg-primary text-sr-text-primary": !invert,
              "bg-sr-text-primary text-sr-bg-primary": invert,
            }
          )}
          side="top"
          align="center"
          sideOffset={4}
          {...props}
        >
          <div
            className={cn("flex items-center gap-1", {
              "text-center": centerContent,
            })}
          >
            <p>{html(content || "")}</p>
          </div>
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  )
}
