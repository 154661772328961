import { format } from "date-fns"
import { Popover } from "./popover"
import { Input } from "./input"
import { Icon } from "./icon"
import { Calendar } from "./calendar"
import { isMobile } from "react-device-detect"

interface DatePickerProps {
  value: string
  onChange: (value: string) => void
  label: string
  placeholder: string
  disabled?: boolean
  disabledDays?: (date: Date) => boolean
}

export const DatePicker = ({
  value,
  onChange,
  label,
  placeholder,
  disabled,
  disabledDays,
}: DatePickerProps): JSX.Element => {
  return isMobile ? (
    <Input
      id="mobileInput"
      type="date"
      onChange={(e) => onChange(e.target.value)}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
    />
  ) : (
    <Popover>
      <Popover.Trigger
        className="relative w-full text-left"
        disabled={disabled}
      >
        <>
          <Input
            id="desktopInput"
            icon="calendar"
            label={label}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
          />
          {value && !disabled && (
            <span
              role="button"
              tabIndex={0}
              onClick={() => onChange("")}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  e.preventDefault()
                  onChange("")
                }
              }}
              className="absolute right-3 top-[34px] cursor-pointer text-day-grey-2"
              aria-label="Clear date"
            >
              <Icon name="close2" />
            </span>
          )}
        </>
      </Popover.Trigger>
      <Popover.Content
        align="end"
        sideOffset={-14}
        alignOffset={0}
        style={{ zIndex: 9999 }}
      >
        <Calendar
          mode="single"
          selected={value ? new Date(value) : undefined}
          onSelect={(date) => {
            if (date) {
              onChange(format(date, "PPP"))
            }
          }}
          disabled={disabledDays}
        />
      </Popover.Content>
    </Popover>
  )
}
