import React, { ReactNode } from "react"
import * as PopoverPrimitive from "@radix-ui/react-popover"
import { cn } from "@/utils/cn"

type PopoverContentProps = PopoverPrimitive.PopoverContentProps & {
  open?: boolean
  onOpenChange?: (open: boolean) => void
  children?: ReactNode
}

const PopoverContent = React.forwardRef<HTMLDivElement, PopoverContentProps>(
  ({ children, ...props }, forwardedRef) => (
    <PopoverPrimitive.Portal>
      <PopoverPrimitive.Content
        sideOffset={8}
        alignOffset={-10}
        {...props}
        className={cn(
          "overflow-hidden rounded-md border border-sr-border-primary bg-sr-bg-primary p-4",
          props.className
        )}
        ref={forwardedRef}
      >
        {children}
      </PopoverPrimitive.Content>
    </PopoverPrimitive.Portal>
  )
)

const PopoverTrigger = PopoverPrimitive.Trigger

const Popover = ({
  open,
  onOpenChange,
  children,
}: PopoverContentProps): JSX.Element => (
  <PopoverPrimitive.Root open={open} onOpenChange={onOpenChange}>
    {children}
  </PopoverPrimitive.Root>
)

Popover.Trigger = PopoverTrigger
Popover.Content = PopoverContent

export { Popover }
